import React from "react";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { rootReducer } from "./reducers/rootReducer";
import { saveState, loadState } from "./localStorage";

const persistedState = loadState();
const middleware = [thunk];

const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(...middleware)
);

store.subscribe(() => {
  saveState(store.getState());
});

// @ts-ignore
export default ({ element }) => <Provider store={store}>{element}</Provider>;
